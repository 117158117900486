import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { CustomRoles } from '@app/CoreModule/Models/CustomRoles';
import { AuthService } from '@app/CoreModule/Services';

@Injectable()
export class RoleGuard implements CanActivate {
    constructor(private auth: AuthService, public router: Router) { }

    canActivate(next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        const expectedRole = [
            CustomRoles.DistrictAdministrator,
            CustomRoles.DistrictStaff,
            CustomRoles.SchoolAdministrator,
            CustomRoles.SchoolStaff
        ]// next.data.expectedRole;
        const userRole = this.auth.getRole();

        if (!this.auth.isLoggedIn() || expectedRole.indexOf(userRole) == -1) {
            this.router.navigate(['/login']);
            return false;
        }
        return true;
    }
}