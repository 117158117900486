import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ratersSearchStudent'
})
export class RatersSearchStudentPipe implements PipeTransform {

    transform(items: any[], arg: string): any[] {
        if (!arg || items.length == 0) {
            return items;
        }
        var result = [];

        arg = arg.toLowerCase();
        var result = items.filter(obj => {
            return (obj['Name'].toLowerCase().indexOf(arg) !== -1) || (obj['Surname'].toLowerCase().indexOf(arg) !== -1);
        })

        if (result.length == 0) {
            return [false];
        }
        return result;
    }

}
