import { Component, Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Grade } from '../Models/Grade';
import { Role } from '../Models/Role';
import { StaffMainDTO, StaffNameDTO, StaffDetailsDTO, StaffEditDTO, StaffActiveDTO } from '../Models/Staff';
import { StudentFullNameDTO, StudentIdFullNameDTO } from '../Models/Student';

@Injectable()
export class StaffService {

    staffAdded = '';

    constructor(private http: HttpClient, @Inject('BASE_URL') public baseUrl: string) { }

    isStaffAdded() {
        return this.staffAdded ? true : false;
    }
    getStaffAdded() {
        return this.staffAdded;
    }
    setStaffAdded(staff) {
        this.staffAdded = staff;
    }
    clearStaffAdded() {
        this.staffAdded = '';
    }

    getStaffRoles() {
        return this.http.get<Array<Role>>(this.baseUrl + 'api/Roles').map(result => {
            return result;
        }).shareReplay();
    }

    getStaffGrades() {
        return this.http.get<Array<Grade>>(this.baseUrl + 'api/Grades').map(result => {
            return result;
        })
            .shareReplay();
    }

    getStaffMain() {
        return this.http.get<Array<StaffMainDTO>>(this.baseUrl + 'api/Staff/GetAllStaffMain').map(result => {
            return result;
        }).shareReplay();
    }

    getStaffNamesExceptRaters() {
        return this.http.get<Array<StaffNameDTO>>(this.baseUrl + 'api/Staff/GetStaffNamesExceptRaters').map(result => {
            return result;
        })
            .shareReplay();
    }

    getAllStaffNames() {
        return this.http.get<Array<StaffNameDTO>>(this.baseUrl + 'api/Staff/GetAllStaffNames').map(result => {
            return result;
        })
            .shareReplay();
    }

    getStaffDetailsById(id: number) {
        return this.http.get<StaffDetailsDTO>(this.baseUrl + `api/Staff/GetStaffDetailsById/${id}`).map(result => {
            return result;
        })
            .shareReplay();
    }

    getStaffOwnDetails() {
        return this.http.get<StaffDetailsDTO>(this.baseUrl + 'api/Staff/GetStaffOwnDetails').map(result => {
            return result;
        })
            .shareReplay();
    }

    getStaffByIdEdit(id: number) {
        return this.http.get<StaffEditDTO>(this.baseUrl + `api/Staff/GetStaffByIdEdit/${id}`).map(result => {
            return result;
        })
            .shareReplay();
    }


    getStaffActiveState() {
        return this.http.get<StaffActiveDTO[]>(this.baseUrl + 'api/Staff/GetStaffActiveState').map(result => {
            return result;
        })
            .shareReplay();
    }

    importStaff(staff) {
        return this.http.post(this.baseUrl + 'api/Staff/Import', staff)
            .shareReplay();
    }

    addStaff(staff) {
        return this.http.post(this.baseUrl + 'api/Staff/Add', staff)
            .shareReplay();
    }

    updateStaff(staff) {
        return this.http.post(this.baseUrl + 'api/Staff/Update', staff)
            .shareReplay();
    }

    deactivateStaff(id: number) {
        return this.http.post(this.baseUrl + 'api/Staff/Deactivate', id)
            .shareReplay();
    }

    activateStaff(id: number) {
        return this.http.post(this.baseUrl + 'api/Staff/Activate', id)
            .shareReplay();
    }

    sendSetupAccountEmail(id: number) {
        return this.http.post(this.baseUrl + 'api/Staff/SendSetupAccountEmail', id)
            .shareReplay();
    }

    sendRegistrationEmails(schoolsIds: Array<number>) {
        return this.http.post(this.baseUrl + 'api/Staff/SendAllRegistrationEmails', schoolsIds)
            .shareReplay();
    }

    getRaterOwnStudents(id: number) {
        return this.http.get<StudentFullNameDTO[]>(this.baseUrl + `api/Staff/GetRaterStudents/${id}`).map(result => {
            return result;
        })
            .shareReplay();
    }

    getStaffAssessedStudents(staffId: number, periodId: number) {
        return this.http.get<StudentIdFullNameDTO[]>(this.baseUrl + `api/Students/GetAssessedStaffStudents/${staffId}/${periodId}`).map(result => {
            return result;
        }).shareReplay();
    }

    getAdminEmail() {
        return this.http.get<any>(this.baseUrl + `api/Staff/GetSchoolAdminEmail`).map(result => {
            return result;
        }).shareReplay();
    }

}

