import { Component, Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';
import { UaStatusReport, UaDataOverviewPyramidsDTO, UaDataOverviewStudentScoresDTO, UaDataOverviewAverageDTO, ProgressMonitoringClassScoresDTO, ProgressMonitoringPeriodAverageDTO, ScaleScorePerPeriodDTO, ProgressMonitoringGroupScaleScoresDTO } from '../Models/Report';


@Injectable()
export class ReportsService {

    constructor(private http: HttpClient, @Inject('BASE_URL') public baseUrl: string) { }

    messageSource = new BehaviorSubject(1);
    currentMessage = this.messageSource.asObservable();
    changeMessage(message: number) {
        this.messageSource.next(message)
    }

    getAllSchoolsUaStatusReport(type) {
        return this.http.get<UaStatusReport[]>(this.baseUrl + `api/Reports/GetAllSchoolsUaStatusReport/${type}`).map(result => {
            return result;
        }).shareReplay();
    }

    getSchoolUaStatusReport(type, schoolId) {
        return this.http.get<UaStatusReport[]>(this.baseUrl + `api/Reports/GetSchoolUaStatusReport/${type}/${schoolId}`).map(result => {
            return result;
        }).shareReplay();
    }

    getAssessmentResults(type, periodId, schoolsIds, teachersIds, gradesIds) {
        return this.http.post<UaDataOverviewPyramidsDTO[]>(this.baseUrl + 'api/Reports/GetAssessmentResults', { UserType: type, PeriodId: periodId, SchoolsIds: schoolsIds, TeachersIds: teachersIds, GradesIds: gradesIds })
            .shareReplay();
    }

    getStudentsWithAssessmentResults(periodId, schoolsIds, gradesIds, teachersIds) {
        return this.http.post<UaDataOverviewStudentScoresDTO[]>(this.baseUrl + 'api/Reports/GetStudentsWithAssessmentResults', { PeriodId: periodId, SchoolsIds: schoolsIds, GradesIds: gradesIds, TeachersIds: teachersIds })
            .shareReplay();
    }

    getSchoolsAverageScores(type, periodId, categoryId, schoolsIds) {
        return this.http.post<UaDataOverviewAverageDTO[]>(this.baseUrl + 'api/Reports/GetSchoolsAverageScores', { UserType: type, PeriodId: periodId, CategoryId: categoryId, SchoolsIds: schoolsIds })
            .shareReplay();
    }

    getClassStudentScoresPerScale(type, schoolsIds, teachersIds, gradesIds, categoryId) {
        return this.http.post<ProgressMonitoringClassScoresDTO[]>(this.baseUrl + 'api/Reports/GetClassStudentScoresPerScale', { UserType: type, SchoolsIds: schoolsIds, TeachersIds: teachersIds, GradesIds: gradesIds, CategoryId: categoryId })
            .shareReplay();
    }

    getSchoolAverageScorePerPeriod(type, periodsIds, schoolsIds, categoryId) {
        return this.http.post<ProgressMonitoringPeriodAverageDTO[]>(this.baseUrl + 'api/Reports/GetSchoolAverageScorePerPeriod', { UserType: type, PeriodsIds: periodsIds, SchoolsIds: schoolsIds, CategoryId: categoryId })
            .shareReplay();
    }

    getStudentScaleScorePerPeriod(studentId) {
        return this.http.get<ScaleScorePerPeriodDTO[][]>(this.baseUrl + `api/Reports/GetStudentScaleScorePerPeriod/${studentId}`).map(result => {
            return result;
        }).shareReplay();
    }

    getPeriodGroupScaleScores(type, schoolsIds, teachersIds, gradesIds) {
        return this.http.post<ProgressMonitoringGroupScaleScoresDTO>(this.baseUrl + 'api/Reports/GetPeriodGroupScaleScores', { UserType: type, SchoolsIds: schoolsIds, TeachersIds: teachersIds, GradesIds: gradesIds })
            .shareReplay();
    }
}  