import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MatDialogRef } from '@angular/material';
import 'rxjs/Rx';
import { StudentsService } from '@services/students.service';

@Component({
    selector: 'app-activate-student',
    templateUrl: './activate-student.component.html',
    styleUrls: ['./activate-student.component.css']
})
export class ActivateStudentComponent implements OnInit {

    studentId: number = -1;

    constructor(public thisDialogRef: MatDialogRef<ActivateStudentComponent>, @Inject(MAT_DIALOG_DATA) public data: number) { }

    ngOnInit() {
        // alert(this.data)
        this.studentId = this.data;
    }

    onCloseConfirm() {
        this.thisDialogRef.close(true);
    }

    onCloseCancel() {
        this.thisDialogRef.close(false);
    }

}
