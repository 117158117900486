import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AccountMainDTO, AccountEditDTO, AccountDetailsDTO, AccountToken } from '../Models/Account';



@Injectable()
export class AccountsService {

  accAdded = '';

  constructor(private http: HttpClient, public router: Router, @Inject('BASE_URL') public baseUrl: string) { }

  getAccountsMain() {
    return this.http.get<Array<AccountMainDTO>>(this.baseUrl + 'api/Accounts/GetAllAccountsMain').map(result => {
      return result;
    })
      .shareReplay();
  }

  getAccountByIdEdit(id) {
    return this.http.get<AccountEditDTO>(this.baseUrl + `api/Accounts/GetAccountByIdEdit/${id}`).map(result => {
      return result;
    })
      .shareReplay();
  }

  getAccountDetailsById(id) {
    return this.http.get<AccountDetailsDTO>(this.baseUrl + `api/Accounts/GetAccountDetailsById/${id}`).map(result => {
      return result;
    })
      .shareReplay();
  }


  addAccount(account) {
    return this.http.post(this.baseUrl + 'api/Accounts/Add', account)
      .shareReplay();
  }

  updateAccount(account) {
    return this.http.post(this.baseUrl + 'api/Accounts/Update', account)
      .shareReplay();
  }

  resendSetupAccountEmail(accountId: number) {
    return this.http.post(this.baseUrl + 'api/Accounts/ResendSetupAccountEmail', accountId)
      .shareReplay();
  }

  sendRenewalEmail(id) {
    return this.http.post(this.baseUrl + 'api/Accounts/SendRenewalEmail', id)
      .shareReplay();
  }

  loginAsAccountAdmin(id) {
    return this.http.post<AccountToken>(this.baseUrl + 'api/Accounts/LoginToAccount', id)
      .shareReplay();
  }

}
