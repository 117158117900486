import { Component, Inject, Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StudentMainDTO, StudentDetailsDTO, StudentEditDTO, StudentActiveDTO, RaterStudentDTO, StudentFileDTO } from '../Models/Student';

@Injectable()
export class StudentsService {

    studentAdded = '';
    storedIds = [];

    constructor(private http: HttpClient, @Inject('BASE_URL') public baseUrl: string) { }

    isStudentAdded() {
        return this.studentAdded ? true : false;
    }
    getStudentAdded() {
        return this.studentAdded;
    }
    setStudentAdded(student) {
        this.studentAdded = student;
    }
    clearStudentAdded() {
        this.studentAdded = '';
    }

    setRaterStudentIds(ids) {
        this.storedIds = ids;
    }
    getRaterStudentIds(ids) {
        return this.storedIds;
    }

    getStudentsMain() {
        return this.http.get<Array<StudentMainDTO>>(this.baseUrl + 'api/Students/GetAllStudentsMain').map(result => {
            return result;
        }).shareReplay();
    }

    getStudentDetailsById(id: number) {
        return this.http.get<StudentDetailsDTO>(this.baseUrl + `api/Students/GetStudentDetailsById/${id}`).map(result => {
            return result;
        }).shareReplay();
    }

    getStudentByIdEdit(id: number) {
        return this.http.get<StudentEditDTO>(this.baseUrl + `api/Students/GetStudentByIdEdit/${id}`).map(result => {
            return result;
        }).shareReplay();
    }

    getStudentsActiveState() {
        return this.http.get<StudentActiveDTO[]>(this.baseUrl + `api/Students/GetStudentsActiveState`).map(result => {
            return result;
        }).shareReplay();
    }

    importStudents(students) {
        return this.http.post(this.baseUrl + 'api/Students/Import', students)
            .shareReplay();
    }

    addStudent(student) {
        return this.http.post(this.baseUrl + 'api/Students/Add', student)
            .shareReplay();
    }

    updateStudent(student) {
        return this.http.post(this.baseUrl + 'api/Students/Update', student)
            .shareReplay();
    }

    changeRater(data) {
        return this.http.post(this.baseUrl + 'api/Students/ChangeRater', data)
            .shareReplay();
    }

    deactivateStudent(id: number) {
        return this.http.post(this.baseUrl + 'api/Students/Deactivate', id)
            .shareReplay();
    }

    activateStudent(id: number) {
        return this.http.post(this.baseUrl + 'api/Students/Activate', id)
            .shareReplay();
    }

    raterGetOwnStudents() {
        return this.http.get<RaterStudentDTO[]>(this.baseUrl + `api/Students/RaterGetOwnStudents`).map(result => {
            return result;
        }).shareReplay();
    }

    removeRater(id: number) {
        return this.http.post(this.baseUrl + 'api/Students/RemoveRater', id)
            .shareReplay();
    }

    sendParentEmail(parentId) {
        return this.http.post(this.baseUrl + 'api/Students/GuardianEmail', parentId)
            .shareReplay();
    }

    getParentLink(studentId, parentId) {
        return this.http.get(this.baseUrl + `api/Students/ParentUrl/${studentId}/${parentId}`)
            .shareReplay();
    }


    getStudentOtherBehavioralDataFile(studentId: number) {
        return this.http.get<StudentFileDTO[]>(this.baseUrl + `api/Files/GetFiles/${studentId}`).map(result => {
            return result;
        }).shareReplay();
    }
    editStudentOtherBehavioralDataFile(fileId: number, title: string) {
        return this.http.post(this.baseUrl + 'api/Files/EditFile', { Id: fileId, FileName: title })
            .shareReplay();
    }
    removeOtherBehavioralDataFile(file) {
        return this.http.post(this.baseUrl + 'api/Files/DeleteFile', file)
            .shareReplay();
    }

    getBlobOtherBehavioralDataFile(studentId, fileId): Observable<HttpEvent<Blob>> {
        return this.http.request(new HttpRequest(
            'GET',
            this.baseUrl + `api/Files/GetFile/${studentId}/${fileId}`,
            null,
            {
                reportProgress: true,
                responseType: 'blob'
            }));
    }
}

