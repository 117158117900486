import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient, HttpHeaders } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AuthInterceptor } from './auth.interceptor';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/el';
registerLocaleData(localeFr, 'el');

import { Globals } from '@app/globals';
import { SharedModule } from './SharedModule/shared/shared.module';
import { SelectivePreloadingStrategy } from './app-selective-preloading';
import { CoreModule } from './CoreModule/core.module';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,

        SharedModule,
        CoreModule,

        AppRoutingModule,
    ],
    providers: [
        HttpClientModule,
        HttpClient,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        Globals,

        SelectivePreloadingStrategy
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
