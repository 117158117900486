import { Component, Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SchoolMainDTO, SchoolNameDTO, SchoolDetailsDTO, SchoolEditDTO, SchoolActiveDTO, SchoolStaffDTO, SchoolMemberDTO } from '../Models/School';
import { StudentMainDTO } from '../Models/Student';


@Injectable()
export class SchoolsService {
    schools = [];
    id = this.schools.length + 1;
    schoolAdded = '';

    constructor(private http: HttpClient, @Inject('BASE_URL') public baseUrl: string) { }

    setSchoolAdded(name) {
        this.schoolAdded = name;
    }
    getSchoolAdded() {
        return this.schoolAdded;
    }
    clearSchoolAdded() {
        this.schoolAdded = '';
    }
    isSchoolAdded() {
        return this.schoolAdded ? true : false;
    }


    getSchoolsMain() {
        return this.http.get<SchoolMainDTO[]>(this.baseUrl + 'api/Schools/GetAllSchoolsMain').map(result => {
            return result;
        }).shareReplay();
    }

    getAllSchoolsNames() {
        return this.http.get<SchoolNameDTO[]>(this.baseUrl + 'api/Schools/GetAllSchoolsNames').map(result => {
            return result;
        })
            .shareReplay();
    }

    getActiveSchoolsNames() {
        return this.http.get<SchoolNameDTO[]>(this.baseUrl + 'api/Schools/GetActiveSchoolsNames').map(result => {
            return result;
        })
            .shareReplay();
    }

    getSchoolDetailsById(id: number) {
        return this.http.get<SchoolDetailsDTO>(this.baseUrl + `api/Schools/GetSchoolDetailsById/${id}`).map(result => {
            return result;
        }).shareReplay();
    }

    getSchoolByIdEdit(id: number) {
        return this.http.get<SchoolEditDTO>(this.baseUrl + `api/Schools/GetSchoolByIdEdit/${id}`).map(result => {
            return result;
        })
            .shareReplay();
    }

    getSchoolsActiveState() {
        return this.http.get<SchoolActiveDTO[]>(this.baseUrl + `api/Schools/GetSchoolsActiveState`).map(result => {
            return result;
        })
            .shareReplay();
    }

    importSchools(schools) {
        return this.http.post(this.baseUrl + 'api/Schools/Import', schools)
            .shareReplay();
    }

    addSchool(school) {
        return this.http.post(this.baseUrl + 'api/Schools/Add', school)
            .shareReplay();
    }

    updateSchool(school) {
        return this.http.post(this.baseUrl + 'api/Schools/Update', school)
            .shareReplay();
    }

    deactivateSchool(id: number) {
        return this.http.post(this.baseUrl + 'api/Schools/DeactivateSchool', id)
            .shareReplay();
    }

    activateSchool(id: number) {
        return this.http.post(this.baseUrl + 'api/Schools/ActivateSchool', id)
            .shareReplay();
    }

    deactivateAllStaff() {
        return this.http.get(this.baseUrl + 'api/Schools/DeactivateAllStaff')
            .shareReplay();
    }

    deactivateAllStudents() {
        return this.http.get(this.baseUrl + 'api/Schools/DeactivateAllStudents')
            .shareReplay();
    }

    deactivateStaff(id: number) {
        return this.http.post(this.baseUrl + 'api/Schools/DeactivateStaff', id)
            .shareReplay();
    }

    deactivateStudents(id: number) {
        return this.http.post(this.baseUrl + 'api/Schools/DeactivateStudents', id)
            .shareReplay();
    }

    getSchoolsStaff(ids: Array<number>) {
        return this.http.post<SchoolStaffDTO[]>(this.baseUrl + `api/Schools/GetAllStaff`, ids).map(result => {
            return result;
        }).shareReplay();
    }

    getActiveSchoolStaff(ids: Array<number>) {
        return this.http.post<SchoolStaffDTO[]>(this.baseUrl + `api/Schools/GetActiveStaff`, ids).map(result => {
            return result;
        })
            .shareReplay();
    }

    getSchoolMembers(id: number) {
        return this.http.get<SchoolMemberDTO[]>(this.baseUrl + `api/Schools/GetAllMembers/${id}`)
            .shareReplay();
    }

    getSchoolsStudents(schoolsIds) {
        return this.http.post<StudentMainDTO[]>(this.baseUrl + `api/Students/GetSchoolsStudents`, schoolsIds)
            .shareReplay();
    }

    getSchoolImage(schoolId: number) {
        return this.http.request(new HttpRequest('GET', this.baseUrl + `api/Files/GetSchoolImage/${schoolId}`, null, { reportProgress: true, responseType: 'blob' })).toPromise();
    }


}


