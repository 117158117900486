import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitBy'
})
export class SplitByPipe implements PipeTransform {

    transform(items: any[], arg: string, page: number): any[] {
        var quantity = parseInt(arg);
        var result = [];
        var start = quantity * (page - 1);
        var end = start + quantity;
        for (var i=start;i<end;i++) {
            if (items[i]==undefined) break;
            result.push(items[i]);
        }
        return result;
  }

}
