import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './SharedModule/shared/Guards/auth.guard';
import { RoleGuard } from './SharedModule/shared/Guards/role.guard';
import { SuperadminGuard } from './SharedModule/shared/Guards/superadmin.guard';
import { DetailsStaffComponent } from './SharedModule/shared/Components/staff/details-staff/details-staff.component';
import { SelectivePreloadingStrategy } from './app-selective-preloading';
import { NavigationBarComponent } from './CoreModule/Components/navigation-bar/navigation-bar.component';


const routes: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    {
        path: '',
        loadChildren: './FeatureModules/user/users.module#UsersModule'
    },
    {
        path: '',
        component: NavigationBarComponent,
        canActivate: [AuthGuard],//, RoleGuard],
        children: [
            {
                path: 'admin',
                canActivate: [SuperadminGuard],
                //canActivate: [AuthGuard, RoleGuard],
                /*data: {
                    expectedRole: [
                        CustomRoles.DistrictAdministrator,
                        CustomRoles.DistrictStaff,
                        CustomRoles.SchoolAdministrator,
                        CustomRoles.SchoolStaff
                    ]
                },*/
                children: [
                    {
                        path: '',
                        redirectTo: '/admin/schools',
                        pathMatch: 'full',
                        canActivate: [AuthGuard, RoleGuard]
                    },
                    {
                        path: 'schools',
                        loadChildren: './FeatureModules/admin/schools/schools.module#SchoolsModule',
                        data: { preload: true, myvar: "appAdmin" }
                    },
                    {
                        path: 'staff',
                        loadChildren: './FeatureModules/admin/staff/staff.module#StaffModule',
                        data: { preload: true, myvar: "appAdmin" }
                    },
                    {
                        path: 'students',
                        loadChildren: './FeatureModules/admin/students/students.module#StudentsModule',
                        data: { preload: true, myvar: "appAdmin" }
                    },
                    {
                        path: 'uaperiod',
                        loadChildren: './FeatureModules/admin/ua-periods/ua-periods.module#UaPeriodsModule',
                        data: { preload: true, myvar: "appAdmin" }
                    },
                    {
                        path: 'reports',
                        loadChildren: './FeatureModules/admin/reports/reports.module#ReportsModule',
                        data: { preload: true, myvar: "appAdmin" }
                    },
                    {
                        path: 'resources',
                        loadChildren: './FeatureModules/admin/resources/resources.module#ResourcesModule',
                        data: { preload: true, myvar: "appAdmin" }
                    }

                ]
            },
            {
                path: 'rater',
                canActivate: [SuperadminGuard],
                //canActivate: [AuthGuard],//, RoleGuard],
                //data: { expectedRole: 'user' },
                children: [
                    {
                        path: '', redirectTo: '/rater/uaperiod', pathMatch: 'full'
                    },
                    {
                        path: 'uaperiod',
                        loadChildren: './FeatureModules/rater/rater-ua-periods/rater-ua-periods.module#RaterUaPeriodsModule',
                        data: { preload: true, myvar: "appRater" }
                    },
                    {
                        path: 'parent-letter',
                        loadChildren: './FeatureModules/rater/rater-reports/rater-reports.module#RaterReportsModule',
                        data: { preload: true, myvar: "appRater" }
                    },
                    {
                        path: 'students',
                        loadChildren: './FeatureModules/rater/rater-students/rater-students.module#RaterStudentsModule',
                        data: { preload: true, myvar: "appRater" }
                    },
                    {
                        path: 'resources',
                        loadChildren: './FeatureModules/rater/rater-resources/rater-resources.module#RaterResourcesModule',
                        data: { preload: true, myvar: "appRater" }
                    }

                ]
            },
            {
                path: 'accounts',
                canActivate: [SuperadminGuard],
                loadChildren: './FeatureModules/accounts/accounts.module#AccountsModule',
                data: { preload: true, myvar: "appSuperAdmin" }
            },
            {
                path: 'user/myAccount',
                canActivate: [SuperadminGuard, AuthGuard],
                component: DetailsStaffComponent
            }
        ]
    },
    { path: 'parent/:id/:parentId/:token', loadChildren: './FeatureModules/parent/parents.module#ParentsModule', },
    { path: '**', redirectTo: '/login' }
];


@NgModule({
    imports: [RouterModule.forRoot(
        routes,
        {
            preloadingStrategy: SelectivePreloadingStrategy
        }
    )
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }