import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MatDialogRef } from '@angular/material';
import 'rxjs/Rx';


@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

    oldPassword = "";
    newPassword = "";
    newPassword2 = "";
    oldPasswordVisible = false;
    newPasswordVisible = false;
    newPassword2Visible = false;

    constructor(public thisDialogRef: MatDialogRef<ChangePasswordComponent>) { }

    ngOnInit() {
    }

    onCloseConfirm() {
        this.thisDialogRef.close({ 'OldPassword': this.oldPassword, 'NewPassword': this.newPassword, 'NewPassword2': this.newPassword2 });
    }

    onCloseCancel() {
        this.thisDialogRef.close(false);
    }
}
