import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterStaffByGrade'
})
export class FilterStaffByGradePipe implements PipeTransform {

    transform(staff: Array<Object>, gradeId): any {
        gradeId = parseInt(gradeId);

        if (staff.length == 0 || gradeId == 0) return staff;

        var result = [];
        staff.forEach(st => {
            if (st['GradesIds'].indexOf(gradeId) != -1) result.push(st);
        })

        return result;

    }

}
