import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchForSpecific'
})
export class SearchForSpecificPipe implements PipeTransform {

    transform(items: any[], property: string, arg: string): any[] {
        if (!arg || items.length == 0) {
            return items;
        }
        var result = [];

        const get = function (str, item) {
            var arr = str.split('.');
            arr.forEach(element => { item = item[element] });
            return item;
        }


        arg = arg.toLowerCase();
        var result = items.filter(obj => {
            return get(property, obj).toLowerCase().indexOf(arg) !== -1;
        })

        return result;
    }
}