import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MatDialogRef } from '@angular/material';
import 'rxjs/Rx';
import { StudentsService } from '@services/students.service';

@Component({
    selector: 'app-delete-file-approval',
    templateUrl: './delete-file-approval.component.html',
    styleUrls: ['./delete-file-approval.component.css']
})
export class DeleteFileApprovalComponent implements OnInit {

    fileName: String = '';

    constructor(private studentsService: StudentsService, public thisDialogRef: MatDialogRef<DeleteFileApprovalComponent>, @Inject(MAT_DIALOG_DATA) public data: string) { }

    ngOnInit() {
        this.fileName = this.data;
    }

    onCloseConfirm() {
        this.thisDialogRef.close(true);
    }

    onCloseCancel() {
        this.thisDialogRef.close(false);
    }

}
