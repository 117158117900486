import { Pipe, PipeTransform } from '@angular/core';
import { log } from 'util';

@Pipe({
  name: 'searchFor'
})
export class SearchForPipe implements PipeTransform {

    transform(items: any[], arg: String): any[] {
        if (!arg || items.length==0 ){
            return items;
        }        
        var result = [];
        var dupl: Object;
        arg = arg.toLowerCase();
        items.forEach(item => {
            dupl = Object.assign({}, item);
            delete dupl['Id'];
            var arr = (<any>Object).values(dupl);
            
            for (var i=0; i<arr.length; i++) {
                if (typeof(arr[i])=='object') {
                    if (arr[i].hasOwnProperty('Id')) {
                        dupl = Object.assign({}, arr[i]);
                        delete dupl['Id'];
                        var item_values=(<any>Object).values(dupl);
                        arr.splice(i, 1, ...item_values); 
                        i+=item_values.length-1;
                    }
                    else {
                        var item_values=(<any>Object).values(arr[i]);
                        arr.splice(i, 1, ...item_values); 
                        i+=item_values.length-1;
                    }
                }
            }
            
            for (var str of arr) {
                if (str.toString().toLowerCase().indexOf(arg) !== -1) {
                    result.push(item);
                    break;
                }
            };
        });        

        if (result.length==0) {
            return [false];
        }      
        
        return result;
  }
}