import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

    transform(items: any[], route: any, filter: any, isNum?: boolean): any[] {
        return items.sort((a, b) => {
            if (isNum) {
                let aLC: number;
                let bLC: number;
                filter = filter.toString();
                if (filter.indexOf('.') > -1) {
                    var attrs = filter.split('.');
                    if (attrs[0] != undefined && attrs[0] != "") { //px  obj { attr1 : 'rand', attr2: {attr21: 'rand21', attr22: 'rand22'} } -> (attr2.attr21.attr22) = rand21 rand22
                        for (var i = 1; i < attrs.length; i++) {
                            aLC = (a[attrs[0]][attrs[i]] != null ? a[attrs[0]][attrs[i]] : (route == "more" ? 200 : -1));
                            bLC = (b[attrs[0]][attrs[i]] != null ? b[attrs[0]][attrs[i]] : (route == "more" ? 200 : -1));
                        }
                    }
                }
                if (route == "more") return aLC < bLC ? -1 : (aLC > bLC ? 1 : 0);
                else if (route == "less") return aLC > bLC ? -1 : (aLC < bLC ? 1 : 0);
            }
            else {
                let aLC: string = '';
                let bLC: string = '';
                filter = filter.toString();
                if (filter.indexOf('.') > -1) {
                    var attrs = filter.split('.');
                    if (attrs[0] != undefined && attrs[0] != "") { //px  obj { attr1 : 'rand', attr2: {attr21: 'rand21', attr22: 'rand22'} } -> (attr2.attr21.attr22) = rand21 rand22
                        for (var i = 1; i < attrs.length; i++) {
                            aLC += a[attrs[0]][attrs[i]].toString().toLowerCase() + ' ';
                            bLC += b[attrs[0]][attrs[i]].toString().toLowerCase() + ' ';
                        }
                    }
                    else { //px  obj { attr1 : 'rand1', attr2: 'rand2'} -> (.attr1.attr2) = rand1 rand2
                        for (var i = 1; i < attrs.length; i++) {
                            aLC += a[attrs[i]].toString().toLowerCase() + ' ';
                            bLC += b[attrs[i]].toString().toLowerCase() + ' ';
                        }
                    }
                }
                else if (a[filter] != null && b[filter] != null) {  // px  obj { attr1 : 'rand1', attr2: 'rand2'} -> (attr1) = rand1
                    aLC = a[filter].toString().toLowerCase();
                    bLC = b[filter].toString().toLowerCase();
                }
                else {
                    //console.log("PREPEI NA EINAI NULL GIA NA MPEI EDO", a[filter], b[filter])
                    if (!a[filter]) return +1;
                    else return -1;
                }
                if (route == "more") return aLC < bLC ? -1 : (aLC > bLC ? 1 : 0);
                else if (route == "less") return aLC > bLC ? -1 : (aLC < bLC ? 1 : 0);
            }
        });
    }

}