import { Component, OnInit } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';

import { MatDialog, MatDialogConfig } from "@angular/material";
import * as $ from 'jquery';
import { trigger, style, animate, transition } from '@angular/animations';
import { DeactivateStudentComponent } from '../deactivate-student/deactivate-student.component';
import { ActivateStudentComponent } from '../activate-student/activate-student.component';
import { DeleteFileApprovalComponent } from '../delete-file-approval/delete-file-approval.component';

import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { HttpRequest, HttpEventType, HttpClient } from '@angular/common/http';

import { Globals } from '@app/globals';
import { StudentUAFullResultsDTO } from '@app/CoreModule/Models/Assessment';
import { CustomRoles } from '@app/CoreModule/Models/CustomRoles';
import { StudentDetailsDTO, StudentFileDTO } from '@app/CoreModule/Models/Student';
import { AuthService, AssessmentService, StudentsService } from '@app/CoreModule/Services';

@Component({
    selector: 'app-details-students',
    templateUrl: './details-students.component.html',
    styleUrls: ['./details-students.component.css'],
    animations: [
        trigger(
            'myAnimation',
            [
                transition(
                    ':enter', [
                    style({ opacity: 0 }),
                    animate('500ms', style({ 'opacity': 1 }))
                ]
                )]
        ),
        trigger(
            'fadeInOut', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('500ms', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('200ms', style({ opacity: 0 }))
            ])
        ]
        )
    ]
})
export class DetailsStudentsComponent implements OnInit {

    student: StudentDetailsDTO = {} as StudentDetailsDTO;
    uaData: StudentUAFullResultsDTO[] = [];

    showParents = false;
    showGraph = false;
    showUAData = false;
    showOtherBehavioralData = false;
    showUATableAfterData = false;
    showDetails = {};
    showDetailsTableOnScroll = true;

    loading = true;
    successfulDeactivation = false;
    successfulActivation = false;

    allowAssessment = false;
    allow123 = false;
    isRater = false;

    hideInApp = false;

    otherData: StudentFileDTO[] = [];
    newFile = { 'Id': 0, 'FileName': '', 'File': null };

    parentEmailPopup = { 'text': '', 'visible': false, 'class': 'success' };

    categories = { 'AttentionNorm': 'ΠΡΟΣΟΧΗ', 'InititiativeNorm': 'ΠΡΩΤΟΒΟΥΛΙΑ', 'SocialSkillsNorm': 'ΦΙΛΟΚΟΙΝΩΝΙΚΟΤΗΤΑ', 'SelfControlNorm': 'ΑΥΤΟΕΛΕΓΧΟΣ', 'SepTotalNorm': 'ΣΥΝΟΛΙΚΟ ΣΕΠ ΣΚΟΡ', 'HomeSchoolNorm': 'ΣΧΕΣΗ ΣΠΙΤΙΟΥ/ΟΙΚΟΓΕΝΕΙΑΣ ΜΕ ΤΟ ΝΗΠΙΑΓΩΓΕΙΟ' }
    categoriesOuter = { 'AttentionNorm': 'Προσοχή', 'InititiativeNorm': 'Πρωτοβουλία', 'SocialSkillsNorm': 'Φιλοκοινωνικότητα', 'SelfControlNorm': 'Αυτοέλεγχος', 'SepTotalNorm': 'Συνολικό ΣΕΠ σκορ', 'HomeSchoolNorm': 'Σχέση σπιτιού/οικογένειας με το νηπιαγωγείο' }

    public progress: number;
    public messageType: boolean = false;
    public messageText: string;

    constructor(public globals: Globals, private sanitizer: DomSanitizer, private http: HttpClient, private location: Location, private authService: AuthService, private assessmentService: AssessmentService, private studentsService: StudentsService, public dialog: MatDialog, private route: ActivatedRoute, private router: Router) {
        var role = this.authService.getRole();
        this.allow123 = (role === CustomRoles.DistrictAdministrator || role === CustomRoles.DistrictStaff || role === CustomRoles.SchoolAdministrator)
        this.isRater = role === CustomRoles.RaterOnly;
    }

    ngOnInit() {
        if (window.location.host == "app.k-sep.com") this.hideInApp = true;

        var id = +this.route.snapshot.paramMap.get('id');
        if (id) {
            this.studentsService.getStudentDetailsById(id).subscribe(
                (student: StudentDetailsDTO) => {
                    this.student = student;

                    this.getFiles();

                    this.assessmentService.getStudentUAResults(id).subscribe(
                        (result: StudentUAFullResultsDTO[]) => {
                            this.uaData = result;
                            this.showUATableAfterData = true;
                        },
                        error => {
                            this.uaData = [];
                            this.showUATableAfterData = false;
                            if ((error.status == 400 || error.status == 500) && typeof error.error == "string") {
                                this.authService.alertError(error.error);
                            }
                            else {
                                this.authService.alertError("Προέκυψε κάποιο σφάλμα.");
                            }
                        },
                    )
                },
                error => this.authService.alertError(`Υπήρξε πρόβλημα κατά την λήψη δεδομένων για ${this.getStudentGenderText2()}.`),
                () => this.loading = false
            );

            this.assessmentService.getAssessmentStatus(id).subscribe(
                (result: boolean) => {
                    this.allowAssessment = result ? true : false;
                },
                error => this.allowAssessment = false
            );
        } else window.location.href = "/admin/students";

        this.scrollEventForStudentDetails();

        this.showDetails = { 'AttentionNorm': false, 'InititiativeNorm': false, 'SocialSkillsNorm': false, 'SelfControlNorm': false, 'SepTotalNorm': false, 'HomeSchoolNorm': false };

    }

    changeDateFormat(datetime) {
        if (datetime) {
            let date = datetime.toString().split('T')[0];
            let newDate = date.split('-').reverse().join('/');
            return newDate;
        }
    }

    sanitize(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    goToForm() {
        var id = +this.route.snapshot.paramMap.get('id');
        var role = this.authService.getRole();
        if (role === CustomRoles.RaterOnly) this.router.navigate([`/rater/uaperiod/students/${id}/form`]);
        else this.router.navigate([`/admin/students/${id}/assessment`])
    }

    getStudentGenderText3() {
        if (this.student.Gender == 'Αρσενικό') return 'του μαθητή';
        else if (this.student.Gender == 'Θηλυκό') return 'της μαθήτριας';
        else return 'Του/Της μαθητή/μαθήτριας';
    }

    scrollEventForStudentDetails() {
        $('div#body').scroll(function () {
            var element = document.getElementById("student_table");
            if (element) {
                var rect = element.getBoundingClientRect();
                if (
                    rect.top >= - element.offsetHeight &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight + element.offsetHeight || document.documentElement.clientHeight + element.offsetHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                ) {
                    $("div#student_details_onscrolldown").hide();
                }
                else {
                    $("div#student_details_onscrolldown").show();
                }
            }
            else $('div#body').unbind('scroll');
        });
    }

    sendParentEmail(parentId) {
        this.parentEmailPopup = { 'text': '', 'visible': false, 'class': 'success' };
        this.studentsService.sendParentEmail(parentId).subscribe(
            result => {
                this.parentEmailPopup = { 'text': 'Επιτυχής αποστολή email.', 'visible': true, 'class': 'success' };
            },
            error => {
                if ((error.status == 400 || error.status == 500) && typeof error.error == "string") {
                    this.parentEmailPopup = { 'text': error.error, 'visible': true, 'class': 'danger' };
                }
                else {
                    this.parentEmailPopup = { 'text': "Προέκυψε κάποιο σφάλμα.", 'visible': true, 'class': 'danger' };
                }
            }
        );
    }

    getParentLink(parentId) {
        this.parentEmailPopup = { 'text': '', 'visible': false, 'class': 'success' };
        this.studentsService.getParentLink(this.student.Id, parentId).subscribe(
            result => {
                window.open(result['Link'], '_blank');
            },
            error => {
                if ((error.status == 400 || error.status == 500) && typeof error.error == "string") {
                    this.parentEmailPopup = { 'text': error.error, 'visible': true, 'class': 'danger' };
                }
                else {
                    this.parentEmailPopup = { 'text': "Προέκυψε κάποιο σφάλμα.", 'visible': true, 'class': 'danger' };
                }
            }
        );
    }

    openDialog(dial, file = null) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        if (dial == "deactivate") {
            const dialogRef = this.dialog.open(DeactivateStudentComponent, {
                width: 'auto',
                data: this.student['Id']
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.studentsService.deactivateStudent(this.student['Id']).subscribe(
                        result => {
                            this.student['Active'] = false;
                            this.successfulDeactivation = true;
                            this.successfulActivation = false;
                        },
                        error => {
                            if ((error.status == 400 || error.status == 500) && typeof error.error == "string") {
                                this.authService.alertError(error.error)
                            }
                            else {
                                this.authService.alertError(`Υπήρξε πρόβλημα κατά την απενεργοποίηση ${this.getStudentGenderText3()}.`)
                            }
                        }
                    )
                }
            });
        }
        else if (dial == "activate") {
            const dialogRef = this.dialog.open(ActivateStudentComponent, {
                width: 'auto',
                data: this.student['Id']
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.studentsService.activateStudent(this.student['Id']).subscribe(
                        result => {
                            this.student['Active'] = true;
                            this.successfulDeactivation = false;
                            this.successfulActivation = true;
                        },
                        error => {
                            if ((error.status == 400 || error.status == 500) && typeof error.error == "string") {
                                this.authService.alertError(error.error)
                            }
                            else {
                                this.authService.alertError(`Υπήρξε πρόβλημα κατά την ενεργοποίηση ${this.getStudentGenderText3()}.`)
                            }
                        }
                    )
                }
            });
        }
        else if (dial == "removeFile") {
            const dialogRef = this.dialog.open(DeleteFileApprovalComponent, {
                width: 'auto',
                data: file.FileName
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    file['EditStatus'] = { 'color': 'darkorange', 'text': 'Προς διαγραφή.' };
                    this.studentsService.removeOtherBehavioralDataFile(file).subscribe(
                        result => {
                            this.getFiles();
                        },
                        error => this.authService.alertError(`Υπήρξε πρόβλημα κατά την διαγραφή του αρχείου "${file['FileName']}".`)
                    )
                }
            });
        }
    }

    getLevelOfClass(ua) {
        if (ua) {
            if (ua['TScore'] < 40) return 'highRisk';
            else if (ua['TScore'] < 46) return 'concern';
            else if (ua['TScore'] < 61) return 'typical';
            else return 'strength';
        }
        else return 'empty';
    }

    getOverallClass(indicator) {
        var arr = [];
        this.uaData.forEach(obj => {
            if (obj[indicator]) arr.push(obj[indicator]['TScore']);
        })

        if (arr.length > 1) {
            var first = arr[0];
            var last = arr[arr.length - 1];
            var dif = first - last;
            if (dif > 10) return 'MuchImproved strength';
            else if (dif > 5) return 'Improved strength';
            if (dif < -10) return 'MuchWorse highRisk';
            else if (dif < -5) return 'Worse highRisk';
            else return 'NoChange';
        }
        else return 'Empty';
    }

    getDifferenceClass(indicator, index) {
        var currentScore = this.uaData[index][indicator]['TScore'];
        var previusScore = null;
        for (var i = index + 1; i < this.uaData.length; i++) {
            if (this.uaData[i][indicator]) {
                previusScore = this.uaData[i][indicator]['TScore']
                break;
            }
        }

        if (previusScore) {
            var dif = previusScore - currentScore;

            if (dif > 10) return 'MuchWorse highRisk';
            else if (dif > 5) return 'Worse highRisk';
            if (dif < -10) return 'MuchImproved strength';
            else if (dif < -5) return 'Improved strength';
            else return 'NoChange';
        }
        else return 'Empty';
    }

    goBack() {
        this.location.back();
    }

    handleFileInput(files: FileList) {
        if (files.item(0)) {
            this.newFile['File'] = files.item(0);
            this.newFile['FileName'] = this.newFile['File'].name;
        }
    }
    searchFileName() {
        if (this.newFile['File']) return this.newFile['File'].name
        else return "Δεν έχετε επιλέξει αρχείο (Μέγιστο 10MB)"
    }

    getStudentGenderText() {
        if (this.student.Gender == 'Αρσενικό') return 'Ο μαθητής';
        else if (this.student.Gender == 'Θηλυκό') return 'Η μαθήτρια';
        else return 'Ο/Η μαθητής/μαθήτρια';
    }

    getStudentGenderText2() {
        if (this.student.Gender == 'Αρσενικό') return 'τον μαθητή';
        else if (this.student.Gender == 'Θηλυκό') return 'την μαθήτρια';
        else return 'Τον/Την μαθητή/μαθήτρια';
    }

    addFile() {
        if (this.newFile['File']) {
            //  if (this.newFile['File'].size < 10485760) {
            this.messageType = false;
            this.messageText = '';
            this.progress = 0;

            const formData: FormData = new FormData();
            formData.append('file', this.newFile['File'], this.newFile['FileName']);

            const uploadReq = new HttpRequest('POST', `api/Files/UploadFile/${this.student.Id}`, formData, {
                reportProgress: true,
                responseType: 'text'
            });


            this.http.request(uploadReq).subscribe(
                event => {
                    if (event.type === HttpEventType.UploadProgress)
                        this.progress = Math.round(100 * event.loaded / event.total);
                    else if (event.type === HttpEventType.Response) {
                        this.messageType = true;
                        this.messageText = 'Επιτυχές ανέβασμα αρχείου.';
                        this.getFiles();
                    }
                },
                error => {
                    this.messageType = false;
                    this.messageText = 'Ανεπιτυχές ανέβασμα αρχείου.';
                }
            );
        }
    }

    getFiles() {
        this.studentsService.getStudentOtherBehavioralDataFile(this.student.Id).subscribe(
            (files: StudentFileDTO[]) => {
                this.otherData = files;
            },
            error => this.otherData = []
        );
    }

    downloadFile(file) {
        this.studentsService.getBlobOtherBehavioralDataFile(this.student.Id, file.Id).subscribe(
            data => {
                switch (data.type) {
                    case HttpEventType.Response:
                        const downloadedFile = new Blob([data.body], { type: data.body.type });
                        const a = document.createElement('a');
                        a.setAttribute('style', 'display:none;');
                        document.body.appendChild(a);
                        a.download = file.FileName;
                        a.href = URL.createObjectURL(downloadedFile);
                        a.target = '_blank';
                        a.click();
                        document.body.removeChild(a);
                        break;
                }
            },
            error => {
                this.authService.alertError(`Υπήρξε πρόβλημα κατά την λήψη του αρχείου "${file['FileName']}".`)
            }
        );
    }

    editFileTitle(file) {
        file['EditStatus'] = { 'color': 'darkorange', 'text': 'Προς επεξεργασία.' };
        this.studentsService.editStudentOtherBehavioralDataFile(file.Id, file.FileName)
            .subscribe(
                result => {
                    file['EditStatus'] = { 'color': 'green', 'text': 'Επιτυχής επεξεργασία.' };
                },
                error => {
                    file['EditStatus'] = { 'color': 'red', 'text': 'Ανεπιτυχής επεξεργασία.' };
                }
            );
    }


}
