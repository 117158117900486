import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { Router } from '@angular/router';
import { AuthService } from '@app/CoreModule/Services';

@Injectable()
export class SuperadminGuard implements CanActivate {
  constructor(private auth: AuthService, public router: Router) { }

  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this.auth.isAdmin() && next.url[0].path == 'accounts') return true;
    else if (!this.auth.isAdmin() && (next.url[0].path == 'admin' || next.url[0].path == 'rater' || next.url[0].path == 'user')) return true;

    this.router.navigate(['/login']);
    return false;
  }
}
