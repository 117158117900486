import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-ensure-action-modal',
  templateUrl: './ensure-action-modal.component.html',
  styleUrls: ['./ensure-action-modal.component.css']
})
export class EnsureActionModalComponent implements OnInit {
  constructor(public thisDialogRef: MatDialogRef<EnsureActionModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  onCloseConfirm() {
    this.thisDialogRef.close(true);
  }

  onCloseCancel() {
    this.thisDialogRef.close(false);
  }

}

