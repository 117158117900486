import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
    selector: 'app-multiple-select',
    templateUrl: './multiple-select.component.html',
    styleUrls: ['./multiple-select.component.css']
})
export class MultipleSelectComponent implements OnInit {
    @Input() values: Array<any>;
    @Input() selectedValues: Array<number> = [];
    @Input() initSelectAll: boolean = false;
    @Input() allText: String;
    @Input() disableAttr: boolean = false;

    hasSelectedAll = true;
    once = true;
    searchText = "";

    @Output() setSelected = new EventEmitter<Array<number>>();

    constructor() { }

    ngOnInit() {
    }

    ngOnChanges(changes) {
        if (this.initSelectAll && this.once) {
            this.selectedValues = this.values.map(x => x.Id);
            this.hasSelectedAll = false;
            this.newOptionSelected();
        }
    }

    equals(objOne, objTwo) {
        if (typeof objOne !== 'undefined' && typeof objTwo !== 'undefined') {
            return objOne === objTwo;
        }
    }

    selectAll(select: NgModel) {
        if (this.hasSelectedAll) select.update.emit(this.values.map(x => x.Id));
        else select.update.emit([]);
        this.hasSelectedAll = !this.hasSelectedAll;
        this.newOptionSelected();
    }

    newOptionSelected() {
        this.setSelected.emit(this.selectedValues);
    }



}
